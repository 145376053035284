import React from "react"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Content, Baner } from "page_components/subpages"
import { Icons, Grid } from "page_components/financing"

const Friends = ({ location }) => {
  const title = "Finansowanie"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header={title}
        description="Pomożemy w uzyskaniu kredytu hipotecznego na Twoje wymarzone mieszkanie! Wspólnie z naszymi partnerami - ekspertami firmy Notus Finanse komfortowo i bez stresu przeprowadzimy Cię przez wszystkie formalności."
        background={
          require("assets/images/financing/financing-hero-background.jpg")
            .default
        }
      />
      <Content
        header="Finansowanie to nasza specjalność"
        description="Każda inwestycja wymaga finansowania, również ta w wymarzone mieszkanie. Zaufaj naszym ekspertom, którzy pomogą Ci uzyskać najlepszą ofertę kredytową."
        image={
          require("assets/images/financing/financing-content-image.png").default
        }
      />
      <Icons />
      <Grid />
      <Baner
        header="Porozmawiajmy<br/> o finansowaniu"
        image={
          require("assets/images/financing/financing-baner-background.jpg")
            .default
        }
        defaultMessage="Chcę poznać ofertę finansowania. Proszę o kontakt."
      />
    </Layout>
  )
}

export default Friends
