import "./styles.scss"

import React from "react"

const Grid = () => {
  return (
    <section className="financing-grid">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="financing-grid__logo">
              <img
                src={require("assets/images/financing/logo-notus.svg").default}
                alt=""
              />
            </div>

            <h4>
              NOTUS Finanse S.A. jest jedną z największych firm pośrednictwa
              finansowego w Polsce.
            </h4>
            <p>
              Posiada kilkadziesiąt oddziałów na terenie całej Polski oraz
              zespół ekspertów finansowych liczący ponad 1500 osób. NOTUS
              Finanse S.A. jako pośrednik kredytów gotówkowych, hipotecznych i
              produktów ubezpieczeniowych nie pobiera opłat.
            </p>
          </div>

          <div className="col-md-6 offset-lg-1">
            <div className="financing-grid__image">
              <img
                src={
                  require("assets/images/financing/financing-grid-image.jpg")
                    .default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
