import "./styles.scss"

import React from "react"

const data = [
  {
    icon: require("assets/images/financing/financing-ico-1.svg").default,
    description:
      "Otrzymasz ofertę kredytową szytą na miarę Twoich oczekiwań i możliwości",
  },
  {
    icon: require("assets/images/financing/financing-ico-2.svg").default,
    description:
      "Przygotowana propozycja będzie możliwie najkorzystniejszą ofertą rynkową negocjowaną z bankami specjalnie dla Ciebie",
  },
  {
    icon: require("assets/images/financing/financing-ico-3.svg").default,
    description:
      "Oszczędzisz czas (brak konieczności zbierania indywidualnie ofert z różnych banków), a przez cały proces przejdziesz bezstresowo",
  },
]

const Icons = () => {
  return (
    <section className="financing-icons">
      <div className="container-fluid">
        <h3>
          Dlaczego warto skorzystać
          <br /> z pomocy naszych ekspertów?
        </h3>
        <div className="row justify-content-center">
          {data?.map((item, index) => (
            <div className="col-md-4 col-sm-6" key={index}>
              <div className="financing-icons__item">
                <div>
                  <img src={item?.icon} alt="" />
                </div>
                <p>{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
